export const menu = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Summaries',
    path: '/summary',
  },
  {
    title: 'Categories',
    path: '/categories',
  },
  {
    title: 'Authors',
    path: '/authors',
  },
  {
    title: 'Our Goal',
    path: '/goal',
  },
  // {
  //   title: 'Contact us',
  //   path: '/contact',
  // },
];
